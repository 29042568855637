import React, { useState, useEffect, lazy, Suspense } from "react";
import LandingFullHeightPicture from "../components/LandingFullHeightPicture";
import CarouselIG from "../components/CarouselIG";
import axios from "axios";
import Video from "../components/Video/Video";
const CollectionItem = lazy(() =>
  import("../components/CollectionItem/CollectionItem")
);
// const Video = lazy(() => import("../components/Video/Video"));

export default (props) => {
  const [data, setData] = useState([]);

  const getCollection = async () => {
    let data = await axios.get(`/getPromotionalItems`).then((res) => res.data);
    setData(data);
  };

  useEffect(() => {
    getCollection();
  }, []);

  return (
    <div id="homepage">
      <h1 style={{ display: "none" }}>
        Murashki is a Luxury lingerie brand. Shop for exquisite lingerie
      </h1>
      <LandingFullHeightPicture />
      {/* <div
        className="collections-homepage"
        style={{ marginTop: "3rem", marginBottom: "2rem" }}
      >
        <div className="hero is-medium">
          <h1
            className="subtitle has-text-centered"
            style={{ marginBottom: "3rem" }}
          >
            NEW ARRIVALS
          </h1>
          <div className="collection-container">
            <Suspense fallback={null}>
              {data.slice(0, 4).map((item, index) => (
                <CollectionItem
                  key={"colection-top-item-" + index}
                  data={item}
                  disableAddToCart
                />
              ))}
            </Suspense>
          </div>
        </div>
      </div> */}
      <div className="hero is-small">
        <h1
          className="subtitle has-text-centered"
          style={{
            marginTop: "2rem",
            marginBottom: "0.5rem",
            letterSpacing: 2,
            fontSize: "1.5rem",
          }}
        >
          Effortlessly chic
        </h1>
        <div className="hero-body" style={{ padding: "0.5rem" }}>
          <div className="grid-fill-tile">
            <a
              className=""
              href="product/silk-lilac-love-bralette-white"
              style={{ cursor: "pointer" }}
            >
              <img src="https://murashkilingerie.com/public/uploads/file-1737410414156.jpg" />
            </a>
            <a href="/product/audrey-sheer-dress" style={{ cursor: "pointer" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Suspense fallback={null}>
                  <Video
                    aspectRatio={"9:16"}
                    src={
                      "https://murashkilingerie.com/public/uploads/file-1697857584649.mp4"
                    }
                  ></Video>
                </Suspense>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="collections-homepage" style={{ marginTop: "2rem" }}>
        <div className="hero is-medium">
          <h2
            className="subtitle has-text-centered"
            style={{
              marginBottom: "3rem",
              letterSpacing: 2,
              fontSize: "1.5rem",
            }}
          >
            In a silky mood
          </h2>
          <div className="collection-container">
            <Suspense fallback={null}>
              {data.slice(0, 4).map((item, index) => (
                <CollectionItem
                  key={"colection-top-item-" + index}
                  data={item}
                  disableAddToCart
                />
              ))}
            </Suspense>
          </div>
        </div>
      </div>
      <div className="hero is-small" style={{ marginTop: "2rem" }}>
        <div className="hero-body">
          <div className="grid-fill-tile">
            <a
              href="/product/silk-lilac-love-bra-coral"
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Suspense fallback={null}>
                  <Video
                    aspectRatio={"9:16"}
                    src={
                      "https://murashkilingerie.com/public/uploads/IMG_8334_2.mp4"
                    }
                  ></Video>
                </Suspense>
              </div>
            </a>
            <a
              className=""
              href="product/lace-monica-panty"
              style={{ cursor: "pointer" }}
            >
              <img src="https://murashkilingerie.com/public/uploads/file-1737413960785.jpg" />
            </a>
          </div>
        </div>
      </div>
      <h2
        className="subtitle has-text-centered"
        style={{
          marginBottom: "2rem",
          letterSpacing: 2,
          fontSize: "1.5rem",
        }}
      >
        Murashki Girls
      </h2>
      <CarouselIG />
      <div className="hero is-small">
        <div className="grid-fill-tile gapless grid-always-three">
          <a
            className="grid-section"
            href="categories/?category=clothing"
            style={{ cursor: "pointer" }}
          >
            <img src="https://murashkilingerie.com/public/uploads/file-1697335415145.jpg" />
            <p className="subtitle text-centered">CLOTHING</p>
            <p className="subtitle text-centered small-underlined">SHOP</p>
          </a>
          <a
            className="grid-section"
            href="categories/?category=bras"
            style={{ cursor: "pointer" }}
          >
            <Video
              aspectRatio={"9:16"}
              src={
                "https://murashkilingerie.com/public/uploads/file-1697341009916.mp4"
              }
            ></Video>

            <p className="subtitle text-centered">BRAS</p>
            <p className="subtitle text-centered small-underlined">SHOP</p>
          </a>
          <a
            className="grid-section"
            href="categories/?category=pantiesandgarters"
            style={{ cursor: "pointer" }}
          >
            <img src="https://murashkilingerie.com/public/uploads/file-1696638545775.jpg" />
            <p className="subtitle text-centered">PANTIES</p>
            <p className="subtitle text-centered small-underlined">SHOP</p>
          </a>
        </div>
      </div>
    </div>
  );
};
