import React from "react";
import Carousel from "react-multi-carousel";
import CarouselIGItem from "./CarouselIGItem";

import "react-multi-carousel/lib/styles.css";

export default () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <Carousel responsive={responsive} infinite>
      <CarouselIGItem
        link="/product/audrey-sheer-dress"
        author="@zozoewitherspoon"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737428562088.JPG"
      />
      <CarouselIGItem
        link="/product/silk-lilac-love-panty-blue"
        author="@ivanovavvaleria"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737429187919.jpg"
      />

      <CarouselIGItem
        link="/product/serena-bralette-black"
        author="@chic_glam_style"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737428758836.jpg"
      />
      <CarouselIGItem
        link="/product/kelly-green-silk-shirt"
        author="@olga_shov"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737429069199.JPG"
      />
      <CarouselIGItem
        link="/product/jasmine-silk-bra-dark-olive"
        author="@chic_glam_style"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737428762801.jpg"
      />
      <CarouselIGItem
        link="/product/lace-monica-bustier"
        author="@kristina.mkva"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737429833016.jpg"
      />
      <CarouselIGItem
        link="/product/lace-black-orchid-garter"
        author="@livia_pillmann"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737428755623.jpg"
      />
      <CarouselIGItem
        link="/product/silk-lilac-love-bra-blue"
        author="@kristina.mkva"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737429258273.jpg"
      />
      <CarouselIGItem
        link="/product/audrey-sheer-dress"
        author="@livia_pillmann"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737433066594.jpg"
      />
      <CarouselIGItem
        link="/product/kelly-green-silk-trousers"
        author="@olga_shov"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737433069769.jpg"
      />
      <CarouselIGItem
        link="/product/lace-black-orchid-bra"
        author="@livia_pillmann"
        imgUrl="https://murashkilingerie.com/public/uploads/file-1737429299177.jpg"
      />
    </Carousel>
  );
};
