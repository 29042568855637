import React, { useEffect, useState, useMemo } from "react";
import useScreen from "../hooks/useScreen";

const widePicturePaths = [
  "https://murashkilingerie.com/public/uploads/file-1737408753468.jpg",
];

const narrowPicturePaths = [
  "https://murashkilingerie.com/public/uploads/file-1737409881306.jpg",
];

export default function LandingFullHeightPicture(props) {
  const [pictureIndex, setPictureIndex] = useState(0);
  const [picClass, setPicClass] = useState("");
  const { width } = useScreen();

  const picturePathsPerScreen = useMemo(() => {
    if (width > 1024) {
      return widePicturePaths;
    }
    return narrowPicturePaths;
  }, [width]);

  useEffect(() => {
    setTimeout(() => {
      setPicClass("zoom-out");
    }, 500);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (pictureIndex === picturePathsPerScreen.length - 1) {
        setPictureIndex(0);
      } else {
        setPictureIndex(pictureIndex + 1);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [pictureIndex]);

  const landingWithPicture = (picture, shouldDisplay) => {
    return (
      <div
        key={picture}
        style={{
          display: shouldDisplay ? "block" : "none",
          backgroundImage: `url(${picture})`,
          cursor: "pointer",
        }}
        className={picClass}
        id="landingFullHeight"
        onClick={() => {
          window.location = "/collections";
        }}
      >
        <div className="hero-body has-text-centered">
          <div className="landing-container">
            <div
              className="landing-text"
              style={{
                fontSize: "1.5rem",

                fontFamily: "Modernline",
                // textTransform: "uppercase",
              }}
            >
              <p>New Arrivals</p>
              {/* <p className="landing-text-small">Effortlessly chic</p> */}
            </div>
            <button
              style={{
                borderRadius: 0,
              }}
              className="button is-light primary-button"
              onClick={() => {
                window.location = "/collections";
              }}
            >
              See more
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {picturePathsPerScreen.map((pic, index) =>
        landingWithPicture(pic, index === pictureIndex)
      )}
    </>
  );
}
