import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";

const VALID_EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default (props) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({
    type: "",
    value: "",
  });

  const submitEmail = async () => {
    if (!email.match(VALID_EMAIL_REGEX)) {
      setMessage({ type: "ERROR", value: "Invalid email address." });
      return;
    }
    const res = await axios.post("/newsletterSub", { email });
    if (res.data.success) {
      setMessage({
        type: "SUCCESS",
        value:
          "Successfully subscribed! Check your email for the discount code.",
      });
    } else {
      setMessage({ type: "ERROR", value: "Email already exists" });
    }
    setTimeout(() => setEmail(""), 5000);
  };

  return (
    <footer id="footer" className="footer">
      <div className="content has-text-centered">
        <div className="container-footer">
          <div className="columns is-vcentered">
            <div className="column">
              <div className="padded-title">
                <a className="is-size-7" href="/story">
                  About us
                </a>
              </div>
              <div className="padded-title">
                <a className="is-size-7" href="/collections">
                  Collections
                </a>
              </div>
              <div
                style={{ fontSize: "0.65rem" }}
                className="padded-title bold"
              >
                Get exclusive previews of our new collections, special offers
                and secret sales.
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    maxWidth: "23rem",
                    margin: "auto",
                  }}
                  className="input is-small is-light dark-bg"
                  placeholder="Subscribe for 15% off"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {message.value !== "" && (
                  <p
                    style={{ textTransform: "none" }}
                    className={`is-size-7 ${
                      message.type == "ERROR"
                        ? "has-text-danger"
                        : "has-text-success"
                    }`}
                  >
                    {message.value}
                  </p>
                )}
                <button
                  style={{
                    maxWidth: "23rem",
                  }}
                  onClick={() => submitEmail()}
                  className="button newsletter-button is-size-7"
                >
                  Subscribe
                </button>
                <p style={{ fontSize: "0.45rem" }}>
                  By signing up for the newsletter, you agree to our{" "}
                  <a href="/info/privacy-policy">privacy policy</a>
                </p>
              </div>
            </div>
            <div className="column">
              <div
                className="padded-title"
                onClick={() => props.setIs20Modalvisible(true)}
              >
                <a className="is-size-7">Give $20 Get $20</a>
              </div>
              <div className="is-size-7 padded-title">
                Follow Us{" "}
                <a
                  target="_blank"
                  href="https://www.instagram.com/murashkilingerie"
                >
                  @murashkilingerie
                </a>
              </div>
              <a href="https://www.instagram.com/murashkilingerie">
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="2x"
                  className="padded-social-icon"
                />
              </a>
              <a href="https://pin.it/6CHP5vG">
                <FontAwesomeIcon
                  icon={faPinterest}
                  size="2x"
                  className="padded-social-icon"
                />
              </a>
              <a href="https://www.facebook.com/murashkilingerie">
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="2x"
                  className="padded-social-icon"
                />
              </a>
            </div>
            <div className="column">
              <div>
                <div className="padded-title">
                  <a className="is-size-7" href="/info/shipping-returns">
                    Shipping & Returns
                  </a>
                </div>
                <div className="padded-title">
                  <a className="is-size-7" href="/sizing-guide">
                    Sizing guide
                  </a>
                </div>
                <div className="padded-title">
                  <a className="is-size-7" href="/info/faq">
                    F.A.Q.
                  </a>
                </div>
                {/* <div className='padded-title'><a className='is-size-7' href='/info/privacy-policy'>Privacy Policy</a></div> */}
                <div className="padded-title">
                  <a className="is-size-7" href="/return-form">
                    Start a return
                  </a>
                </div>
              </div>
              <div>
                <div className="email is-size-7" style={{ marginTop: "2rem" }}>
                  Contact us via email for all inquiries
                </div>
                <a
                  href="mailto:support@murashkilingerie.com"
                  className="email is-size-7 padded-title"
                >
                  support@murashkilingerie.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="legal-container">
        <p className="legal-link">
          {new Date().getFullYear()} © Murashki LLC. All rights reserved.
        </p>
        <a className="legal-link" href="/info/terms">
          Terms and Conditions
        </a>
        <a className="legal-link" href="/info/privacy-policy">
          Security and Privacy
        </a>
      </div>
    </footer>
  );
};
