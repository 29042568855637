import React from "react";

export default ({ link, author, imgUrl }) => (
  <div style={{ padding: "0.5rem" }}>
    <a className="" href={link} style={{ cursor: "pointer" }}>
      <img src={imgUrl} />
    </a>
    <p>{author}</p>
  </div>
);
